import React, { useState, useRef } from 'react'
import FormLabel from 'react-bootstrap/FormLabel'

import chainEventHandler from '../utilities/chainEventHandler'

import BooleanInput from './BooleanInput'

export interface BooleanCollectionProps {
  className?: string
  custom?: boolean
  label?: string
  name: string
  options: {
    label?: string
    value?: React.ReactNode
  }[]
  type?: 'checkbox' | 'radio' | 'switch'
  value?: React.ReactNode
  onChange?(value: unknown): void
  onValueChange?(value: unknown): void
}

const BooleanCollection = ({
  name,
  options,
  onChange,
  onValueChange,
  value,
  type,
  label,
  ...otherProps
}: BooleanCollectionProps) => {
  const [checkedValues, setCheckedValues] = useState([])
  const previousValue = useRef([])

  const isRadio = type === 'radio'
  const useInternalState = value !== undefined

  if (useInternalState && value !== previousValue.current) {
    // @ts-expect-error TS(2322) FIXME: Type 'string | number | boolean | ReactElement<any... Remove this comment to see the full error message
    previousValue.current = value

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | number | boolean | Reac... Remove this comment to see the full error message
    setCheckedValues(value)
  }

  let handleValueChange
  if (!isRadio && onValueChange !== undefined) {
    handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value: newValue, checked } = event.target

      let newCheckedValues
      if (checked) {
        newCheckedValues = [newValue, ...checkedValues]
      } else {
        newCheckedValues = [...checkedValues].filter((item) => item !== newValue)
      }

      // @ts-expect-error TS(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
      setCheckedValues(newCheckedValues)
      onValueChange(newCheckedValues)
    }
  }

  const handleChange = chainEventHandler(handleValueChange, onChange)

  return (
    <fieldset>
      {label && (
        <FormLabel as="legend" className="font-size-md">
          {label}
        </FormLabel>
      )}
      {options.map((option, index) => {
        const { value: optionValue, label: optionLabel, ...optionProps } = option
        let checked
        if (useInternalState) {
          checked = isRadio
            ? `${option.value}` === `${value}`
            : // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              checkedValues.includes(`${option.value}`)
        }

        return (
          <BooleanInput
            name={`${name}[]`}
            id={`${name}_${index + 1}`}
            // @ts-expect-error TS(2322) FIXME: Type 'string | number | true | ReactElement<any, s... Remove this comment to see the full error message
            key={optionValue || 'empty'}
            onChange={handleChange}
            onValueChange={isRadio ? onValueChange : undefined}
            // @ts-expect-error TS(2322) FIXME: Type 'ReactNode' is not assignable to type 'string... Remove this comment to see the full error message
            value={optionValue}
            type={type}
            checked={checked}
            label={optionLabel || label}
            {...optionProps}
            {...otherProps}
          />
        )
      })}
    </fieldset>
  )
}

BooleanCollection.defaultProps = {
  className: '',
  custom: undefined,
  label: undefined,
  onChange: undefined,
  onValueChange: undefined,
  type: BooleanInput.defaultProps.type,
  value: undefined,
}

export default BooleanCollection
