import { deepFreeze } from '../../../utilities/deepIterate'

const address = {
  name: 'NORD.investments A/S',
  road: 'Applebys Plads 7',
  zipcode: 'DK-1411',
  city: 'København K',
}

// @ts-expect-error TS(2339) FIXME: Property 'parts' does not exist on type '{ name: s... Remove this comment to see the full error message
address.parts = [address.name, address.road, `${address.zipcode} ${address.city}`]
// @ts-expect-error TS(2339) FIXME: Property 'full' does not exist on type '{ name: st... Remove this comment to see the full error message
address.full = address.parts.join('\n')

const contactInformation = deepFreeze({
  phone: '+45 7196 9628',
  phoneLink: 'tel:+4571969628',
  email: 'hello@nord.investments',
  emailLink: 'mailto:hello@nord.investments',
  cvr: '37226939',
  address: Object.freeze(address),
})

export default contactInformation
