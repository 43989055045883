import classNames from 'classnames'
import { capitalize } from 'lodash'
import React from 'react'

import { ReactComponent as Graphic } from './Graphic.inline.svg'
import styles from './index.module.scss'

export const POSITIONS = ['top', 'bottom']

export interface Props {
  className?: string
  position?: any // TODO: PropTypes.oneOf(POSITIONS)
}

const RoundedCut = ({ className, position, ...otherProps }: Props) => (
  <div
    className={classNames(styles.roundedCut, className, styles[`position${capitalize(position)}`])}
  >
    <Graphic className={styles.graphic} {...otherProps} />
  </div>
)

RoundedCut.defaultProps = {
  className: undefined,
  position: 'bottom',
}

export default RoundedCut
