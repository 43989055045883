import PageTransition from '@nord/ui/src/components/ApplicationWrapper/plugins/PageTransition'
import { selectCurrentPortfolioId } from '@nord/ui/src/store/current/portfolioId'
import { selectPortfolioIds } from '@nord/ui/src/store/current/portfolios'
import isEmpty from 'lodash/isEmpty'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'

import PortfolioContext from '../../context/PortfolioContext'
import useRedirect from '../../hooks/useRedirect'
import NoPortfolioIdPage from '../NotAuthPages/NoPortfolioIdPage'

import Rebalancing from './pages/Rebalancing'
import RebalancingApproved from './pages/RebalancingApproved'
import RebalancingCompleted from './pages/RebalancingCompleted'
import RebalancingRejected from './pages/RebalancingRejected'
import RiskScoreChange from './pages/RiskScoreChange'
import RiskScoreChangeApproved from './pages/RiskScoreChangeApproved'
import ScreeningError from './pages/ScreeningError'
import ScreeningQuestionsStart from './pages/ScreeningQuestionsStart'
import Start from './pages/Start'
import SuitabilityQuestions from './pages/SuitabilityQuestions'
import SuitabilityQuestionsFailed from './pages/SuitabilityQuestionsFailed'
import SuitabilityUpdateCompleted from './pages/SuitabilityUpdateCompleted'
import SuitabilityUpdateRejected from './pages/SuitabilityUpdateRejected'

const AuthPages = () => {
  const location = useLocation()

  const [newRiskScore, setNewRiskScore] = useState()
  const [newEsg, setNewEsg] = useState()

  const portfolioId = useSelector(selectCurrentPortfolioId)
  const portfoliosIds = useSelector(selectPortfolioIds)

  const handleNewRiskScoreChange = (value: any) => setNewRiskScore(value)
  const handleNewEsgChange = (value: any) => setNewEsg(value)

  const context = useMemo(
    () => ({
      newRiskScore,
      onNewRiskScoreChange: handleNewRiskScoreChange,
      newEsg,
      onNewEsgChange: handleNewEsgChange,
    }),
    [newEsg, newRiskScore],
  )

  useRedirect()

  const unauthorisedPortfolioId =
    !!portfolioId && !isEmpty(portfoliosIds) && !portfoliosIds.includes(portfolioId)

  if (!portfolioId || unauthorisedPortfolioId) return <NoPortfolioIdPage />

  return (
    <PortfolioContext.Provider value={context}>
      <PageTransition pageKey={location.pathname}>
        <Switch location={location}>
          <Route path="/portefoeljer/:id/start" component={Start} exact />
          <Route
            path="/portefoeljer/:id/egnethedstest/start"
            component={ScreeningQuestionsStart}
            exact
          />
          <Route path="/portefoeljer/:id/egnethedstest/mangler" component={ScreeningError} exact />
          <Route path="/portefoeljer/:id/egnethedstest" component={SuitabilityQuestions} exact />
          <Route
            path="/portefoeljer/:id/egnethedstest/fejlet"
            component={SuitabilityQuestionsFailed}
            exact
          />
          <Route
            path="/portefoeljer/:id/egnethed/fuldfoert"
            component={SuitabilityUpdateCompleted}
            exact
          />
          <Route
            path="/portefoeljer/:id/egnethed/afvist"
            component={SuitabilityUpdateRejected}
            exact
          />
          <Route path="/portefoeljer/:id/rebalancer" component={Rebalancing} exact />
          <Route
            path="/portefoeljer/:id/rebalancer/godkendt"
            component={RebalancingApproved}
            exact
          />
          <Route path="/portefoeljer/:id/rebalancer/afvist" component={RebalancingRejected} exact />
          <Route
            path="/portefoeljer/:id/rebalancer/fuldfoert"
            component={RebalancingCompleted}
            exact
          />
          <Route path="/portefoeljer/:id/ny-risikoprofil" component={RiskScoreChange} exact />
          <Route
            path="/portefoeljer/:id/ny-risikoprofil/godkendt"
            component={RiskScoreChangeApproved}
            exact
          />
          <Redirect to={`/portefoeljer/${portfolioId}/start`} />
        </Switch>
      </PageTransition>
    </PortfolioContext.Provider>
  )
}

export default AuthPages
