import { deepFreeze } from '../../../utilities/deepIterate'

const numbers = deepFreeze({
  minimumInvestment: {
    default: 10000,
    freeAssets: 30000,
    simple: 10000,
    pension: 30000,
    discounted: 2000000,
  },
  memberReferralAmount: 500,
  totalFees: {
    freeAssets: {
      standard: {
        base: 0.0075,
        discounted: 0.006,
      },
      esg: {
        base: 0.008,
        discounted: 0.0065,
      },
      stocksOnly: {
        base: 0.008,
        discounted: 0.0065,
      },
    },
    pension: {
      standard: {
        base: 0.008,
        discounted: 0.0065,
      },
    },
  },
  yearlyHistoricalReturn: {
    freeAssets: 0.0944,
    pension: 0.0942,
  },
  yearlyRebalancing: {
    freeAssets: 0.03,
    pension: 0.05,
  },
  portfolioEtfCount: 7,
})

export default numbers
