import Cookies from 'js-cookie'
import isEmpty from 'lodash/isEmpty'

const writeUtmCookie = () => {
  const utm: Record<string, string> = {}
  new URLSearchParams(window.location.search).forEach((value, key) => {
    if (key.startsWith('utm_')) {
      const formattedKey = key.replace('utm_', '')
      utm[formattedKey] = value
    }
  })

  if (!isEmpty(utm)) {
    Cookies.set('utm', JSON.stringify(utm), {
      expires: 365,
      secure: true,
      domain: '.nord.investments',
    })
  }
}

export default writeUtmCookie
