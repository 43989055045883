import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'

import FileDropzone from '../../FileDropzone'
import LoadingButton from '../../LoadingButton'

import useStoreDocumentsApi from './useStoreDocumentsApi'
import useUploadDocumentApi from './useUploadDocumentApi'

export interface Props {
  email: string
  onError?: (...args: any[]) => any
  onUpload?: (...args: any[]) => any
}

const Upload = ({ email, onUpload, onError }: Props) => {
  const [files, setFiles] = useState({})
  const [uploaded, setUploaded] = useState(false)
  const [failed, setFailed] = useState(false)

  const apiOptions = { onError }
  const { loading, storeDocuments } = useStoreDocumentsApi(email, apiOptions)

  const { loading: loadingUpload, uploadDocument } = useUploadDocumentApi(email, apiOptions)

  const handleValueChange = (newFiles: any) => {
    setUploaded(false)
    setFailed(false)
    setFiles(newFiles)
  }

  const handleSubmit = async () => {
    setUploaded(false)
    setFailed(false)

    const { success } = await storeDocuments(files)

    if (!success) {
      setFailed(true)

      return
    }

    if (onUpload) onUpload(files)

    setFiles({})
    setUploaded(true)
  }

  return (
    <>
      {uploaded && (
        <Alert className="text-center mb-3" variant="info">
          Tak for at uploade dokumentation, det er nu modtaget og vil blive behandlet hurtigst
          muligt.
        </Alert>
      )}
      {failed && (
        <Alert className="text-center mb-3" variant="danger">
          Noget gik galt.
        </Alert>
      )}
      <FileDropzone
        // @ts-expect-error TS(2322) FIXME: Type '{ className: string; upload: (file: any) => ... Remove this comment to see the full error message
        className="text-center"
        upload={uploadDocument}
        onValueChange={(values) => handleValueChange(values)}
        files={files}
      />
      <div className="d-flex justify-content-center mt-4">
        <LoadingButton
          // @ts-expect-error TS(2322) FIXME: Type '{ children: string; onClick: () => Promise<v... Remove this comment to see the full error message
          onClick={handleSubmit}
          variant="success"
          disabled={isEmpty(files)}
          loading={loading || loadingUpload}
          filled
        >
          Godkend og indsend
        </LoadingButton>
      </div>
    </>
  )
}

Upload.displayName = 'DocumentsForm.Upload'

Upload.defaultProps = {
  onError: undefined,
  onUpload: undefined,
}

export default Upload
