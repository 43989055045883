import React from 'react'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Mail from 'react-feather/dist/icons/mail'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Phone from 'react-feather/dist/icons/phone'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import RefreshCw from 'react-feather/dist/icons/refresh-cw'

import { getConfig } from '../../configuration'
import FullScreenSection from '../FullScreenSection'
import Header from '../Header'
import Icon from '../Icon'
import IconButton from '../IconButton'
import SectionContainer from '../SectionContainer'
import Text from '../Text'

const { email, emailLink, phone, phoneLink } = getConfig('contactInformation')

const ErrorPage = () => {
  const handleReload = () => window.location.reload()

  return (
    <FullScreenSection>
      <Header mobile>
        <Navbar role="banner">
          <Header.Logo />
        </Navbar>
      </Header>
      <Container fluid>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; className: string; }'... Remove this comment to see the full error message */}
        <SectionContainer className="text-center">
          <Text as="h3" align="center" variant="dark">
            Vi beklager, der er sket en fejl...
          </Text>
          <Text as="p">
            Vi har rapporteret fejlen, og vil forsøge at udbedre den hurtigst muligt. Du kan
            genindlæse siden for at prøve igen. Oplever du forsat fejl så kontakt os, vi sidder klar
            til at hjælpe!
          </Text>
          <a className="link-dark text-decoration-none mr-5" href={emailLink}>
            <Icon icon={Mail} className="mr-2" />
            {email}
          </a>
          <a className="link-dark text-decoration-none" href={phoneLink}>
            <Icon icon={Phone} className="mr-2" />
            {phone}
          </a>
          <div className="d-flex justify-content-center">
            <IconButton icon={RefreshCw} onClick={handleReload} className="mt-4">
              Genindlæs
            </IconButton>
          </div>
        </SectionContainer>
      </Container>
    </FullScreenSection>
  )
}

export default ErrorPage
