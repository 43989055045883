import PageTransition from '@nord/ui/src/components/ApplicationWrapper/plugins/PageTransition'
import { selectCurrentPortfolioId } from '@nord/ui/src/store/current/portfolioId'
import React from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'

import ForgotPasswordPage from './ForgotPasswordPage'
import LoginPage from './LoginPage'

const NotAuthPages = () => {
  const location = useLocation()
  const portfolioId = useSelector(selectCurrentPortfolioId)

  return (
    <PageTransition pageKey={location.pathname}>
      <Switch location={location}>
        <Route path="/brugere/logind" component={LoginPage} exact />
        <Route path="/brugere/adgangskode/glemt" component={ForgotPasswordPage} exact />
        <Redirect to={`/brugere/logind?portfolio_id=${portfolioId}`} />
      </Switch>
    </PageTransition>
  )
}

export default NotAuthPages
