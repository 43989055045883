import Card from '@nord/ui/src/components/Card'
import Text from '@nord/ui/src/components/Text'
import React from 'react'

import MyAccountButton from '../../../../components/MyAccountButton'
import Page from '../../../../components/Page'

const SuitabilityQuestionsFailed = () => (
  <Page>
    <Card>
      <Card.Body>
        <Text as="h3" align="center">
          Du er ikke egnet til at investere
        </Text>
        <div className="px-lg-5 mx-lg-5 text-center">
          <Text as="p">
            På baggrund af din opdaterede investeringsplan, kan NORD.investments ikke længere
            vurdere, hvorvidt det er hensigtmæssigt for dig at investere hos os.
          </Text>
          <Text as="p">
            NORD.investments har ikke det nødvendige grundlag for at yde investeringsrådgivning
            eller videreformidle nye ordrer.
          </Text>
          <Text as="p">
            Er du sikker på, at dine seneste svar er korrekte? Hvis ikke, kan du tage spørgsmålene
            igen. Får vi ikke nye oplysninger fra dig, vil vi kontakte dig omkring dit fremtidige
            kundeforhold i NORD.investments.
          </Text>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <MyAccountButton />
        </div>
      </Card.Body>
    </Card>
  </Page>
)

export default SuitabilityQuestionsFailed
