import PropTypes from 'prop-types'
import React, { useContext, useRef } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Paginator, { usePaginationContext } from '../../Paginator'
import SimpleMarkdownText from '../../SimpleMarkdownText'
import Text from '../../Text'
import AnswersContext from '../AnswersContext'

import AnswerField from './AnswerField'
import InfoModal from './InfoModal'
import QuestionField from './QuestionField'
import WarningModal from './WarningModal'

export interface Props {
  question: any // TODO: PropTypes.shape(questionPropTypeShape)
  onAnswer: (...args: any[]) => any
  onFailure?: (...args: any[]) => any
}

const QuestionPage = ({ question: questionData, onAnswer, onFailure, ...otherProps }: Props) => {
  const { onNext, last } = usePaginationContext(false)
  // @ts-expect-error TS(2339) FIXME: Property 'answers' does not exist on type 'never[]... Remove this comment to see the full error message
  const { answers } = useContext(AnswersContext)
  const warningRef = useRef()

  const {
    question,
    description,
    infoText,
    footer,
    warning,
    answers: answersArray,
    number: questionNumber,
  } = questionData
  const questionIndex = questionNumber - 1
  const answerData = answers[questionIndex]
  const { answer: currentAnswer } = answerData || {}

  const handleAcceptAnswer = (answer: any, answerIndex: any) => {
    onAnswer({ answerIndex, ...questionData, answer }, last)
    onNext()
  }

  const handleAnswer = (answer: any, answerIndex: any) => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (warning && warningRef.current.validate(answerIndex)) return

    handleAcceptAnswer(answer, answerIndex)
  }

  const handleFailure = (answer: any, answerIndex: any) =>
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onFailure({ answerIndex, ...questionData, answer })

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: any[]; container: boolean; showP... Remove this comment to see the full error message
    <Paginator.Page container={false} showPagination {...otherProps}>
      <QuestionField questionIndicator={questionNumber} question={question} />
      <Row>
        {description && (
          <Col xs={12} lg={{ span: 8, offset: 2 }}>
            <Text as="p" align="center" className="mb-4">
              <SimpleMarkdownText>{description}</SimpleMarkdownText>
            </Text>
          </Col>
        )}
        {infoText && <InfoModal {...infoText} />}
      </Row>
      <Row xs={12} data-cy="answers">
        {answersArray.map((answer: any, index: any) => (
          <AnswerField
            key={answer}
            index={index}
            answer={answer}
            selected={answer === currentAnswer}
            onAnswer={handleAnswer}
          />
        ))}
      </Row>
      <Row>
        {footer && (
          <Text as="div" className="pt-5 mx-auto">
            {footer}
          </Text>
        )}
      </Row>
      {warning && (
        <WarningModal
          {...warning}
          onAccept={handleAcceptAnswer}
          onFailure={handleFailure}
          answer={answersArray[warning.answerIndex]}
          ref={warningRef}
        />
      )}
    </Paginator.Page>
  )
}

QuestionPage.defaultProps = {
  onFailure: undefined,
}

export default QuestionPage
