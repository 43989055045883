import Card from '@nord/ui/src/components/Card'
import Text from '@nord/ui/src/components/Text'
import React from 'react'

import MyAccountButton from '../../../../components/MyAccountButton'
import Page from '../../../../components/Page'
import PortfolioTitle from '../../../../components/PortfolioTitle'

const RebalancingApproved = () => (
  <Page>
    <Card>
      <Card.Body>
        <Text as="h3" align="center">
          Din portefølje vil nu blive rebalanceret
        </Text>
        <PortfolioTitle />
        <Text as="p" align="center" className="px-lg-4">
          Du vil modtage en mail fra os, så snart din portefølje er blevet rebalanceret.
        </Text>
        <div className="d-flex justify-content-center mt-5">
          <MyAccountButton />
        </div>
      </Card.Body>
    </Card>
  </Page>
)

export default RebalancingApproved
