import axios from 'axios'
import isEqual from 'lodash/isEqual'
import React, { useState, useLayoutEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowLeft from 'react-feather/dist/icons/arrow-left'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import { getConfig } from '../configuration'
import useApi from '../hooks/useApi'
import { getCookieConsent, setCookieConsent } from '../utilities/cookieConsent'
import writeUtmCookie from '../utilities/writeUtmCookie'

import BaffleButton from './BaffleButton'
import BooleanCollection from './BooleanCollection'
import Icon from './Icon'
import IconButton from './IconButton'
import Text from './Text'

const websiteDomain = getConfig('domains.website')
const cookiePolicyPath = getConfig('urls.website.privacyPolicy')

const cookieConsentVersion = 1

const analyticsClient = axios.create({
  baseURL: websiteDomain,
})

const cookieOptions = [
  {
    label: (
      <>
        <span className="font-weight-bold">Nødvendige cookies</span> gør det muligt at benytte vores
        hjemmeside.
      </>
    ),
    value: 'necessary',
    disabled: true,
  },
  {
    label: (
      <>
        <span className="font-weight-bold">Funktionelle cookies</span> gør, at du får den bedste
        brugeroplevelse, når du bruger vores hjemmeside.
      </>
    ),
    value: 'functional',
  },
  {
    label: (
      <>
        <span className="font-weight-bold">Statistik cookies</span> hjælper os til at få indsigt i
        hvordan vores besøgende bruger vores hjemmeside.
      </>
    ),
    value: 'statistical',
  },
  {
    label: (
      <>
        <span className="font-weight-bold">Marketing cookies</span> hjælper os til at sikre
        relevante og målrettede annoncer.
      </>
    ),
    value: 'marketing',
  },
]
const initialAcceptedCookies = cookieOptions.map((option) => option.value).sort()

export interface Props {
  onAccept?: (...args: any[]) => any
}

const CookieModal = ({ onAccept }: Props) => {
  const [showCookieSettings, setShowCookieSettings] = useState(false)
  const [acceptedCookies, setAcceptedCookies] = useState(initialAcceptedCookies)
  const [showCookieModal, setShowCookieModal] = useState(undefined)
  const submitConsent = useApi('/cookie_consents', {
    method: 'POST',
    errorHandling: {
      ignore: {
        server: true,
      },
    },
  })

  const handleBackButton = () => setShowCookieSettings(false)

  const handleCookiesChange = (cookiesArray: any) => {
    setAcceptedCookies(cookiesArray)
  }

  const handleAcceptOnlyNecessaryCookies = () => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
    setShowCookieModal(false)
    setCookieConsent('necessary')
    analyticsClient.get('/analytics/consent/reject')
    submitConsent({
      cookieConsent: {
        accepted: false,
        data: acceptedCookies,
        version: cookieConsentVersion,
      },
    })
  }

  const handleAcceptAllCookies = () => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
    setShowCookieModal(false)
    setCookieConsent('all')
    writeUtmCookie()
    analyticsClient.get('/analytics/consent/accept')
    submitConsent({
      cookieConsent: {
        accepted: true,
        data: initialAcceptedCookies,
        version: cookieConsentVersion,
      },
    })

    if (onAccept) onAccept()
  }

  const handleAcceptOfSelectedCookies = () => {
    if (isEqual(initialAcceptedCookies, acceptedCookies.sort())) handleAcceptAllCookies()
    else handleAcceptOnlyNecessaryCookies()
  }

  useLayoutEffect(() => {
    const cookiesType = getCookieConsent()

    if (cookiesType === 'all') {
      writeUtmCookie()
    }

    if (cookiesType !== 'all' && cookiesType !== 'necessary') {
      analyticsClient.get('/analytics/consent/show')
      // @ts-expect-error TS(2345) FIXME: Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
      setShowCookieModal(true)
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
    if (cookiesType === 'all' || cookiesType === 'necessary') setShowCookieModal(false)
  }, [])

  return (
    <Modal show={showCookieModal} size="lg" backdrop="static" centered>
      <Modal.Body className="py-5">
        {showCookieSettings && (
          <>
            <div className="d-flex flex-row align-items-center">
              {/* @ts-expect-error TS(2322) FIXME: Type '{ icon: any; size: number; className: string... Remove this comment to see the full error message */}
              <Icon icon={ArrowLeft} size={2} className="mr-auto" onClick={handleBackButton} />
              <Modal.Title as="h3" className="mr-auto">
                Indstillinger
              </Modal.Title>
            </div>
            <Text as="p" align="center" className="mx-lg-5">
              Vælg hvilke cookies, du gerne vil acceptere.
            </Text>
            <div className="mx-lg-5">
              <BooleanCollection
                className="mx-lg-5 my-1"
                onValueChange={handleCookiesChange}
                // @ts-expect-error TS(2322) FIXME: Type '({ label: Element; value: string; disabled: ... Remove this comment to see the full error message
                options={cookieOptions}
                value={acceptedCookies}
              />
            </div>
            <div className="d-flex flex-column-reverse flex-lg-row justify-content-lg-center align-items-center mt-3">
              <BaffleButton
                variant="link"
                className="text-dark my-4 my-lg-0 mr-lg-5"
                onClick={handleAcceptOfSelectedCookies}
              >
                Accepter valgte cookies
              </BaffleButton>
              <IconButton
                icon={ArrowRight}
                onClick={handleAcceptAllCookies}
                variant="success"
                filled
              >
                Tillad alle cookies
              </IconButton>
            </div>
          </>
        )}
        {!showCookieSettings && (
          <>
            <Modal.Title as="h3" className="text-center">
              Samtykke til cookies
            </Modal.Title>
            <Text as="p" align="center" className="mx-lg-4">
              Vi bruger cookies for at give dig en bedre oplevelse, når du bruger vores hjemmeside,
              samt til markedsføring og analyse. Du kan læse mere om hvilke cookies vi bruger, samt
              hvordan vi behandler den data vi indsamler i vores{' '}
              <a href={cookiePolicyPath} target="_blank" rel="noopener noreferrer">
                Cookie- og privatlivspolitik
              </a>
              .
            </Text>
            <Text as="p" align="center" className="mx-lg-4">
              Læs vores cookiepolitik her, hvor du også til hver en tid kan trække dit samtykke
              tilbage.
            </Text>
            <div className="d-flex flex-column-reverse flex-lg-row justify-content-lg-center align-items-center">
              <BaffleButton
                variant="link"
                className="text-dark my-4 my-lg-0 mr-lg-5"
                onClick={() => setShowCookieSettings(true)}
              >
                Indstillinger
              </BaffleButton>
              <IconButton
                icon={ArrowRight}
                onClick={handleAcceptAllCookies}
                variant="success"
                filled
              >
                Tillad alle cookies
              </IconButton>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

CookieModal.defaultProps = {
  onAccept: undefined,
}

export default CookieModal
