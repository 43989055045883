// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'airb... Remove this comment to see the full error message
import { childrenOf } from 'airbnb-prop-types'
import classNames from 'classnames'
import React from 'react'
import { CSSTransition } from 'react-transition-group'

import extractTransitionClasses from '../utilities/extractTransitionClasses'

import styles from './FullPageTransition.module.scss'

const defaultTimeout = parseInt(styles.timeout, 10)

export interface Props {
  children: any // TODO: childrenOf(PropTypes.node)
  className?: string
  timeout?: number
  appear?: boolean
}

// TODO: Do not start transition on anchor tag links (like #header)
// TODO: Do not scroll to top on page change
// TODO: Scroll to anchor tag links after page change
// TODO: On unload, stop animation on white screen
const FullPageTransition = ({ children, className, ...otherProps }: Props) => (
  <>
    {children}
    {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
    <CSSTransition
      classNames={extractTransitionClasses({
        styles,
        className: 'fullPageTransition',
        appearKey: 'exit',
      })}
      {...otherProps}
    >
      <aside className={classNames(styles.fullPageTransition, className)}>
        <aside className={classNames(styles.layer, styles.layerSand)} />
        <aside className={classNames(styles.layer, styles.layerWhite)} />
      </aside>
    </CSSTransition>
  </>
)

FullPageTransition.defaultProps = {
  appear: true,
  className: undefined,
  timeout: defaultTimeout,
}

export const fullPageTransitionDuration = defaultTimeout

export default FullPageTransition
