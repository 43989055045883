import { deepFreeze } from '../../../utilities/deepIterate'

const production = deepFreeze({
  debug: false,
  domains: {
    website: 'https://www.nord.investments',
    backend: 'https://api.nord.investments',
    webSocket: 'wss://api.nord.investments/cable',
    app: 'https://app.nord.investments',
    onboarding: 'https://signup.nord.investments',
    blog: 'https://blog.nord.investments',
    suitabilityFlow: 'https://egnethed.nord.investments',
  },
  keys: {
    googleAnalyticsId: 'UA-88580223-1',
    googleTagManagerId: 'GTM-5594RBW',
    googleOptimizeId: 'GTM-M375NK7',
    intercomId: 'iguc7lmz',
  },
  urls: {
    external: {
      expectedReturn: 'https://expected-return.herokuapp.com/expected',
      historicalReturn: {
        privateAndCompany: 'https://expected-return.herokuapp.com/historical',
        pension: 'https://expected-return.herokuapp.com/pension_historical',
      },
      kreditDataConsent: 'https://app.kreditdata.dk/consent/323pv7q049/ixcc6i9nct',
    },
  },
})

export default production
