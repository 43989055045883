import React from 'react'

import FullPageTransition from '../../FullPageTransition'
import PageSwitchTransition from '../../PageSwitchTransition'

export interface Props {
  children?: React.ReactNode
  pageKey: string
}

/**
 * Plugin for adding a full page transition when +pageKey+ changes.
 * If using together with react-router: MUST be rendered as direct parent of Switch component.
 *
 * @component
 */
const PageTransition = ({ pageKey, children, ...otherProps }: Props) => (
  <PageSwitchTransition pageKey={pageKey} leave {...otherProps}>
    <FullPageTransition>{children}</FullPageTransition>
  </PageSwitchTransition>
)

PageTransition.defaultProps = {
  children: undefined,
}

PageTransition.displayName = 'ApplicationWrapper.Plugins.PageTransition'

export default PageTransition
