import React from 'react'

import { environment } from '../../../configuration/config'
import loadGoogleTagManager from '../../../utilities/loadGoogleTagManager'
import setupABTest from '../../../utilities/setupABTest'
import CookieModal from '../../CookieModal'

const { isDevelopment } = environment

export interface Props {
  children: React.ReactNode
  onAccept?: (...args: any[]) => any
}

const CookieConsent = ({ children, onAccept }: Props) => {
  if (isDevelopment) return children

  const handleAcceptCookies = () => {
    loadGoogleTagManager()
    setupABTest()
    if (onAccept) onAccept()
  }

  return (
    <>
      <CookieModal onAccept={handleAcceptCookies} />
      {children}
    </>
  )
}

CookieConsent.defaultProps = {
  onAccept: undefined,
}

CookieConsent.displayName = 'ApplicationWrapper.Plugins.CookieConsent'

export default CookieConsent
