import classNames from 'classnames'
import React from 'react'

import styles from './FullScreenBackground.module.scss'
import RoundedCut from './RoundedCut'

export interface Props {
  children?: React.ReactNode
  className?: string
}

const FullScreenBackground = ({ className, children, ...otherProps }: Props) => (
  <div className={classNames(className, styles.background)} {...otherProps}>
    <RoundedCut />
    {children}
  </div>
)

FullScreenBackground.defaultProps = {
  children: undefined,
  className: undefined,
}

export default FullScreenBackground
