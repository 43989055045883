import Card from '@nord/ui/src/components/Card'
import Text from '@nord/ui/src/components/Text'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import formatDate from '@nord/ui/src/utilities/formatDate'
import React from 'react'
import Button from 'react-bootstrap/Button'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import Page from '../../../../components/Page'

const ScreeningQuestionsStart = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'suitabilityTestSucceededAt' does not exi... Remove this comment to see the full error message
  const { suitabilityTestSucceededAt, id: portfolioId } = useObjectSelector(selectCurrentPortfolio)

  const lastUpdatedDate = suitabilityTestSucceededAt && formatDate(suitabilityTestSucceededAt)

  return (
    <Page>
      <Card>
        <Card.Body>
          <Text as="h3" align="center">
            Seneste oplysninger er fra d. {lastUpdatedDate}
          </Text>
          <Text as="p" align="center" className="px-lg-4">
            Du har sidst opdateret dine oplysninger d. {lastUpdatedDate}. Du skal derfor tage en ny
            investeringsplan. Dette er for at vurdere om, der er sket væsentlige ændringer i din
            økonomi, tidshorisont eller risikovillighed og om vores anbefaling af risikoprofil
            fortsat egner sig til dig.
          </Text>
          <div className="d-flex justify-content-center mt-5">
            <Button as={Link} to={`/portefoeljer/${portfolioId}/egnethedstest`}>
              Næste
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Page>
  )
}

export default ScreeningQuestionsStart
