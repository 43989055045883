import BooleanInput from '@nord/ui/src/components/BooleanInput'
import Card from '@nord/ui/src/components/Card'
import DescriptionList from '@nord/ui/src/components/DescriptionList'
import Loader from '@nord/ui/src/components/Loader'
import Text from '@nord/ui/src/components/Text'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import {
  selectCurrentPortfolio,
  updateCurrentPortfolio,
} from '@nord/ui/src/store/current/portfolioId'
import getRiskScoreDescription from '@nord/ui/src/utilities/getRiskScoreDescription'
import { numberToCurrency, numberToPercent } from '@nord/ui/src/utilities/numberFormatter'
import React, { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { useDispatch } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link, useHistory } from 'react-router-dom'

import Page from '../../../../components/Page'
import PortfolioContext from '../../../../context/PortfolioContext'
import usePortfolioId from '../../../../hooks/usePortfolioId'

const onlyRiskScoreTitle = true

const RiskScoreChange = () => {
  const dispatch = useDispatch()
  const portfolioId = usePortfolioId()
  const history = useHistory()

  // @ts-expect-error TS(2339) FIXME: Property 'riskScore' does not exist on type 'unkno... Remove this comment to see the full error message
  const { riskScore, esg, advisedEsg, advisedRiskScore, suitabilityFlow } =
    useObjectSelector(selectCurrentPortfolio)

  // @ts-expect-error TS(2339) FIXME: Property 'newRiskScore' does not exist on type 'un... Remove this comment to see the full error message
  const { newRiskScore, newEsg } = useContext(PortfolioContext)

  const previousAdvisedRiskScore = newRiskScore && advisedRiskScore
  const newAdvisedRiskScore = newRiskScore || advisedRiskScore

  const [riskScoreChangePriceAccepted, setRiskScoreChangePriceAccepted] = useState(false)

  const flowUrl = suitabilityFlow === 'rebalancing' ? 'rebalancer' : 'egnethed/afvist'

  const handleToggleAccept = (value: any) => setRiskScoreChangePriceAccepted(value)

  const fetchRiskScorePrice = useApi(
    `/onboarding/${portfolioId}/portfolios/change_risk_score_price`,
    {
      method: 'GET',
      withCredentials: true,
    },
  )

  const { data: riskScoreChangePrice, loading: loadingRiskScoreChangePrice } = useApiRequest(
    fetchRiskScorePrice,
    {
      autoCall: true,
      payload: {
        riskScore: newAdvisedRiskScore,
        esg: newEsg,
      },
    },
  )

  const { priceLow, priceHigh, costPercentage } = riskScoreChangePrice || {}

  const priceChangeContent = !loadingRiskScoreChangePrice &&
    !!priceLow &&
    !!priceHigh &&
    costPercentage && (
      <Text as="p" align="center" className="px-lg-5 mt-2">
        Dette indebærer nogle omkostninger til henholdsvis salg og køb af værdipapirer på imellem{' '}
        <strong>{`${numberToCurrency(priceLow)} og ${numberToCurrency(priceHigh)}`}</strong>,
        svarende til{' '}
        {numberToPercent(costPercentage, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })}{' '}
        af din porteføljes værdi.
      </Text>
    )

  const handleNewRiskProfileApproval = async () => {
    await dispatch(
      updateCurrentPortfolio({
        riskScore: newAdvisedRiskScore,
      }),
    )
    history.push(`/portefoeljer/${portfolioId}/ny-risikoprofil/godkendt`)
  }

  const descriptionListData = [
    {
      riskScore,
      label: 'Din valgte risikoprofil',
      esg,
    },
  ]

  if (previousAdvisedRiskScore)
    descriptionListData.push({
      riskScore: previousAdvisedRiskScore,
      label: 'Tidligere anbefaling',
      esg: advisedEsg,
    })

  if (newAdvisedRiskScore)
    descriptionListData.push({
      riskScore: newAdvisedRiskScore,
      label: 'Ny anbefaling',
      esg: newEsg,
    })

  return (
    <Page>
      <Card>
        <Card.Body>
          <Text as="h3" align="center">
            Der er en ny anbefaling, der passer bedre til dig.
          </Text>
          <Text as="p" align="center" className="px-lg-4">
            Siden vores seneste anbefaling, har din økonomi ændret sig, hvorfor vi har en ny
            anbefaling til dig.
          </Text>
          <Card className="mx-lg-5 shadow">
            <Card.Body>
              <DescriptionList>
                {descriptionListData.map(({ label, riskScore: riskScoreValue, esg: esgValue }) => (
                  <DescriptionList.Item
                    key={label}
                    label={label}
                    value={
                      <>
                        <strong>{riskScoreValue}</strong> / 20{' '}
                        {esgValue && <strong>(ansvarlig) </strong>}
                        <Text variant="secondary">
                          ({getRiskScoreDescription(riskScoreValue, onlyRiskScoreTitle)})
                        </Text>
                      </>
                    }
                  />
                ))}
              </DescriptionList>
            </Card.Body>
          </Card>
          <Text as="p" align="center" className="mt-5 px-lg-4">
            Vi anbefaler, at du bliver investeret efter den anbefalede risikoprofil:{' '}
            <strong>
              {newRiskScore} {newEsg && '(ansvarlig)'}
            </strong>
            . Ønsker du dette?
          </Text>
          {loadingRiskScoreChangePrice ? <Loader className="text-center" /> : priceChangeContent}
          <div className="d-flex justify-content-center">
            <BooleanInput
              name="riskScoreChangePriceAcceptance"
              label="Jeg bekræfter, at jeg vil skifte risikoprofil."
              type="switch"
              checked={riskScoreChangePriceAccepted}
              onCheckedChange={handleToggleAccept}
            />
          </div>
          <div className="d-flex justify-content-center mt-5 children-mx-3">
            <Button as={Link} to={`/portefoeljer/${portfolioId}/${flowUrl}`} variant="danger">
              Nej
            </Button>
            <Button disabled={!riskScoreChangePriceAccepted} onClick={handleNewRiskProfileApproval}>
              Ja
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Page>
  )
}

export default RiskScoreChange
