import Card from '@nord/ui/src/components/Card'
import CurrencyInput from '@nord/ui/src/components/CurrencyInput'
import Form from '@nord/ui/src/components/Form'
import Text from '@nord/ui/src/components/Text'
import useApi from '@nord/ui/src/hooks/useApi'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import React, { useState } from 'react'

import Page from '../../../../components/Page'

import CarAssetField from './CarAssetField'
import DebtModal from './DebtModal'
import validationSchema from './validationSchema'

const labelDescriptions = ({ isCompany }: { isCompany: boolean }) => ({
  investableAssets:
    'Hvor stor er din investerbare formue (kontanter, indestående i banken, og værdipapirer)?',
  debt: isCompany ? (
    <>
      Hvad er din virksomheds samlede gæld? Du skal <strong>IKKE</strong> medregne boliggæld såsom
      realkreditlån og boliglån. Gæld kan f.eks. være en kassekredit, der er trukket på.
    </>
  ) : (
    <>
      Hvad er din samlede gæld? Du skal <strong>IKKE</strong> medregne boliggæld såsom
      realkreditlån, andelsboliglån eller andre boliglån, samt SU-lån. Gæld kan f.eks. være
      forbrugslån eller billån.
    </>
  ),
  carAsset: 'Hvad er den aktuelle salgsværdi af din bil?',
})

export interface DebtTestProps {
  isSubmitting: boolean
  onSubmit(data: unknown): unknown
}

const DebtTest = ({ isSubmitting, onSubmit }: DebtTestProps) => {
  const [showWarning, setShowWarning] = useState(false)

  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
  const { id: portfolioId, onboardingFlow } = useObjectSelector(selectCurrentPortfolio)

  const isCompany = onboardingFlow === 'company'

  const validateDebt = useApi(`/portfolios/${portfolioId}/suitability_tests/debt_validations`, {
    method: 'POST',
    withCredentials: true,
    errorHandling: {
      ignore: {
        client: true,
      },
    },
  })

  const handleValidateDebt = async ({
    hasCarDebt,
    ...data
  }: {
    carAsset: unknown
    debt: unknown
    hasCarDebt: boolean
    investableAssets: unknown
  }) => {
    const response = await validateDebt({
      suitabilityTest: {
        ...data,
      },
    })

    const { success } = response

    if (success) onSubmit(data)
    else setShowWarning(true)
  }

  const handleHideAlert = () => setShowWarning(false)

  return (
    <Page>
      <Card>
        <Card.Body>
          <Text as="h3" align="center" className="mb-3">
            Vi skal bruge lidt information om din økonomi
          </Text>
          <Form
            onSubmit={handleValidateDebt}
            validationSchema={validationSchema}
            labelDescriptions={labelDescriptions({ isCompany })}
            // @ts-expect-error TS(2322) FIXME: Type '{ children: (false | Element)[]; onSubmit: (... Remove this comment to see the full error message
            validateOnMount
          >
            <DebtModal
              // @ts-expect-error TS(2322) FIXME: Type '{ show: boolean; onSubmit: (...args: any[]) ... Remove this comment to see the full error message
              show={showWarning}
              onSubmit={onSubmit}
              isSubmitting={isSubmitting}
              onHide={handleHideAlert}
            />
            <Form.FieldGroup name="investableAssets">
              {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ maskOptions, value, ...otherProps }: Cu... Remove this comment to see the full error message */}
              <Form.Field as={CurrencyInput} />
            </Form.FieldGroup>
            <Form.FieldGroup name="debt">
              {/* @ts-expect-error TS(2322) FIXME: Type '{ ({ maskOptions, value, ...otherProps }: Cu... Remove this comment to see the full error message */}
              <Form.Field as={CurrencyInput} />
            </Form.FieldGroup>
            {!isCompany && <CarAssetField />}
            <div className="d-flex justify-content-center my-4">
              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; }' is n... Remove this comment to see the full error message */}
              <Form.SubmitButton variant="primary">Fortsæt</Form.SubmitButton>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Page>
  )
}

export default DebtTest
