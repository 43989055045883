import classNames from 'classnames'
import times from 'lodash/times'
import React, { useState } from 'react'

import Paginator, { usePaginationContext } from '../Paginator'

import './Navigation.styles.scss'

const Navigation = () => {
  const { number, count, onChange } = usePaginationContext(false)
  const [pageProgress, setPageProgress] = useState(number)

  const handleClick = (newPage: any) => {
    if (number > pageProgress) setPageProgress(number)

    onChange(newPage)
  }

  return (
    <Paginator.Navigation.Container>
      <ul className="pagination-list">
        {times(count, (index) => {
          const currentPage = index + 1
          const clickable = currentPage <= Math.max(pageProgress, number)

          return (
            <li className="pagination-list-item" key={index}>
              <button
                aria-label={`page${index}`}
                type="button"
                onClick={clickable ? () => handleClick(currentPage) : undefined}
                role="link"
                tabIndex={0}
                className={classNames('pagination-dot', {
                  active: number === currentPage,
                  disabled: !clickable,
                })}
                data-cy="pagination"
              />
            </li>
          )
        })}
      </ul>
    </Paginator.Navigation.Container>
  )
}

export default Navigation
