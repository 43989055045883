import Text from '@nord/ui/src/components/Text'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { valueToDate } from '@nord/ui/src/utilities/formatDate'
import { formatDistance } from 'date-fns'
import { da } from 'date-fns/locale'
import React from 'react'
import Button from 'react-bootstrap/Button'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import PortfolioTitle from '../../../../components/PortfolioTitle'

type Props = {
  startPath: string
}

const SuitabilityMandatoryUpdateContent = ({ startPath }: Props) => {
  // @ts-expect-error TS(2339) FIXME: Property 'suitabilityTestSucceededAt' does not exi... Remove this comment to see the full error message
  const { suitabilityTestSucceededAt } = useObjectSelector(selectCurrentPortfolio)

  const currentDate = new Date()
  const lastUpdatedDate = suitabilityTestSucceededAt && valueToDate(suitabilityTestSucceededAt)

  const lastUpdatedDistance = formatDistance(lastUpdatedDate, currentDate, {
    locale: da,
  })

  return (
    <>
      <Text as="h3" align="center">
        Du skal opdatere dine oplysninger
      </Text>
      <PortfolioTitle />
      <Text as="p" align="center" className="px-lg-4">
        Det er nu {lastUpdatedDistance} siden, at du sidst opdaterede dine oplysninger. For at kunne
        yde den bedste rådgivning og sikre, at din investering fortsat passer til din økonomi, skal
        du igen opdatere dine oplysninger, så vi kan revurdere din risikoprofil.
      </Text>
      <Text as="p" align="center" className="px-lg-4">
        Det tager ca. 5 minutter - klik herunder for at starte.
      </Text>
      <div className="d-flex justify-content-center mt-2 mb-5">
        <Button as={Link} to={startPath}>
          Start
        </Button>
      </div>
    </>
  )
}

export default SuitabilityMandatoryUpdateContent
