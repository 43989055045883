import Card from '@nord/ui/src/components/Card'
import Text from '@nord/ui/src/components/Text'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import React from 'react'

import MyAccountButton from '../../../../components/MyAccountButton'
import Page from '../../../../components/Page'

const RiskScoreChangeApproved = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'fullRiskScoreTitle' does not exist on ty... Remove this comment to see the full error message
  const { fullRiskScoreTitle } = useObjectSelector(selectCurrentPortfolio)

  return (
    <Page>
      <Card>
        <Card.Body>
          <Text as="h3" align="center">
            Din portefølje bliver nu ændret
          </Text>
          <Text as="p" align="center" className="px-lg-4">
            Din portefølje vil nu blive ændret til risikoproﬁl <strong>{fullRiskScoreTitle}</strong>
            . Du vil modtage en mail fra os, så snart din portefølje er blevet ændret.
          </Text>
          <div className="d-flex justify-content-center mt-5">
            <MyAccountButton />
          </div>
        </Card.Body>
      </Card>
    </Page>
  )
}

export default RiskScoreChangeApproved
