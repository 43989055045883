import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import { useDispatch } from 'react-redux'

import useQuery from '../../hooks/useQuery'
import { signInUser } from '../../store/current/user'
import ReduxProviderContext from '../ApplicationWrapper/plugins/ReduxProvider/ReduxProviderContext'
import Form from '../Form'
import PasswordInput from '../PasswordInput'
import Text from '../Text'
import Transition from '../Transition'

import EmailInput from './EmailInput'
import Footer from './Footer'
import validationSchema from './validationSchema'

const labelDescriptions = {
  username: 'E-mail',
  password: 'Adgangskode',
}

const alertTypes = {
  incorrectPassword: {
    type: 'Incorrect password',
    variant: 'danger',
    message: 'Adgangskode er forkert.',
  },
  createPassword: {
    type: 'Create password',
    variant: 'warning',
    message:
      'Du skal vælge en adgangskode før du kan fortsætte din oprettelse. Skriv din email nedenfor og klik "Næste", så sender vi dig link og instruktioner til at komme videre.',
  },
  timeout: {
    type: 'Timeout',
    variant: 'warning',
    message: 'Du er blevet logget ud efter for lang inaktivitet. Log ind igen for at fortsætte.',
  },
}

export interface Props {
  children?: React.ReactNode
  onSignIn?(user: unknown): Promise<void>
}

const LoginForm = ({ children, onSignIn, ...otherProps }: Props) => {
  // @ts-expect-error TS(2339) FIXME: Property 'loadPortfolios' does not exist on type '... Remove this comment to see the full error message
  const { loadPortfolios, setPortfolioIdOnLoad } = useContext(ReduxProviderContext) || {
    loadPortfolios: true,
    setPortfolioIdOnLoad: true,
  }

  type PartialAlert = Partial<{ message: string; type: string; variant: string }>
  const [alert, setAlert] = useState<PartialAlert>({})

  const [allowLogin, setAllowLogin] = useState(false)
  const emailInputRef = useRef()
  const dispatch = useDispatch()

  const { createPassword, timeout } = useQuery()

  const emailParam = queryString.parse(window.location.search).email
  const initialValues = { username: emailParam }

  const handleSubmit = async (
    { username: email, password }: { username: string; password: string },
    {
      setFieldTouched,
    }: { setFieldTouched(field: string, isTouched?: boolean, shouldValidate?: boolean): void },
  ) => {
    if (!allowLogin) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      emailInputRef.current.handleEmailCheck()
      setFieldTouched('password', false, false)

      return
    }

    const dataForSignIn = {
      user: { email, password },
    }

    try {
      const userData = await dispatch(
        // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
        signInUser({
          loadPortfolios,
          setPortfolioId: setPortfolioIdOnLoad,
          ...dataForSignIn,
        }),
        // @ts-expect-error TS(2339) FIXME: Property 'unwrap' does not exist on type 'AsyncThu... Remove this comment to see the full error message
      ).unwrap()
      if (onSignIn) await onSignIn(userData)
    } catch {
      setAlert(alertTypes.incorrectPassword)
    }
  }

  useEffect(() => {
    if (createPassword) setAlert(alertTypes.createPassword)
    if (timeout) setAlert(alertTypes.timeout)
  }, [createPassword, timeout])

  return (
    <>
      <Text as="h2" align="center" size="xl" className="mb-5">
        {alert.type === 'Password created' ? 'E-mail sendt' : 'Velkommen tilbage!'}
      </Text>
      {!isEmpty(alert) && (
        <Alert variant={alert.variant} className="text-center">
          {alert.message}
        </Alert>
      )}
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema(allowLogin)}
        labelDescriptions={labelDescriptions}
        // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; onSubmit: ({ email, p... Remove this comment to see the full error message
        validateOnMount
        {...otherProps}
      >
        <EmailInput
          ref={emailInputRef}
          alert={alert}
          allowLogin={allowLogin}
          onChangeAlert={setAlert}
          onChangeAllowLogin={setAllowLogin}
        />
        <Transition.Expand in={allowLogin} key="loginButton">
          {allowLogin && (
            <>
              <Form.FieldGroup name="password">
                {/* @ts-expect-error TS(2322) FIXME: Type '(props: Props & RefAttributes<any>) => React... Remove this comment to see the full error message */}
                <Form.Field inputComponent={PasswordInput} autoFocus />
              </Form.FieldGroup>
              <div className="d-flex justify-content-center py-3">
                {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; }' is n... Remove this comment to see the full error message */}
                <Form.SubmitButton variant="primary">Log ind</Form.SubmitButton>
              </div>
            </>
          )}
        </Transition.Expand>
        <Footer allowLogin={allowLogin}>{children}</Footer>
      </Form>
    </>
  )
}

LoginForm.defaultProps = {
  children: undefined,
  onSignIn: undefined,
}

export default LoginForm
