import classNames from 'classnames'
import pick from 'lodash/pick'
import React, { useCallback, useState, useRef, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Upload from 'react-feather/dist/icons/upload'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import useCurrentDevice from '../../hooks/useCurrentDevice'
import extractProps from '../../utilities/extractProps'
import extractTransitionClasses from '../../utilities/extractTransitionClasses'
import Card from '../Card'
import Icon from '../Icon'

import FileItem from './FileItem'
import styles from './index.module.scss'

const timeout = parseInt(styles.timeout, 10)
const timeoutStagger = parseInt(styles.timeoutStagger, 10)
// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
const itemHeight = parseFloat(styles.itemHeight, 10)

const dropzonePropKeys = [
  'accept',
  'disabled',
  'getFilesFromEvent',
  'maxSize',
  'minSize',
  'multiple',
  'noClick',
  'noDrag',
  'noDragEventsBubbling',
  'noKeyboard',
  'onDragEnter',
  'onDragLeave',
  'onDragOver',
  'onDrop',
  'onDropRejected',
  'onFileDialogCancel',
  'preventDropOnDocument',
]

const serializableFileProperties = ['lostModified', 'lastModifiedDate', 'name', 'size', 'type']

export const formatFilesToObject = (newFiles: any) =>
  newFiles.reduce((result: any, file: any) => {
    const newFile = pick(file, serializableFileProperties)
    result[file.name] = newFile

    return result
  }, {})

export interface FileDropzoneProps {
  files?: any
  id?: string
  onDropAccepted?: (...args: any[]) => any
  onValueChange?: (...args: any[]) => any
  upload: (...args: any[]) => any
}

const FileDropzone = ({
  id,
  onDropAccepted,
  onValueChange,
  upload,
  files: externalFiles,
  ...otherProps
}: FileDropzoneProps) => {
  const [files, setFiles] = useState({})
  const [uploadComplete, setUploadComplete] = useState(false)
  const [unloadedFilesCount, setUnloadedFilesCount] = useState(0)
  const asyncUploadedFiles = useRef({})

  const filesCount = Object.keys(files).length
  const loadedFilesCount = filesCount - unloadedFilesCount
  const totalTimeout = timeout + timeoutStagger * unloadedFilesCount
  const entering = unloadedFilesCount > 0
  const exiting = unloadedFilesCount < 0

  let labelContent = (
    <span className="link-base">
      Klik for at uploade <Icon icon={Upload} />
    </span>
  )

  const currentDevice = useCurrentDevice()
  const loadingCurrentDevice = !currentDevice

  // @ts-expect-error TS(2339) FIXME: Property 'desktop' does not exist on type 'never'.
  if (!loadingCurrentDevice && currentDevice.desktop())
    labelContent = (
      <>
        Træk dine dokumenter herind eller{' '}
        <span className="link-base">
          klik for at uploade <Icon icon={Upload} />
        </span>
      </>
    )

  useEffect(() => {
    if (externalFiles !== undefined) setFiles(externalFiles)
  }, [externalFiles])

  const uploadWithCallbacks = useCallback(
    (file: any) =>
      upload(file)
        .then(() => {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          asyncUploadedFiles.current[file.name] = true
        })
        .catch(() => {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          asyncUploadedFiles.current[file.name] = false
        })
        .finally(() => setUploadComplete(true)),
    [upload],
  )

  const handleDropAccepted = useCallback(
    (addedFiles: any) => {
      setUnloadedFilesCount(addedFiles.length)
      const newFilesObject = formatFilesToObject(
        addedFiles.map((file: any) => {
          const newFile = Object.assign(file, {
            status: 'uploading',
          })

          uploadWithCallbacks(newFile)

          return newFile
        }),
      )

      const newFiles = { ...files, ...newFilesObject }
      setFiles(newFiles)

      if (onDropAccepted) onDropAccepted(newFiles)
      if (onValueChange) onValueChange(newFiles)
    },
    [onDropAccepted, onValueChange, files, uploadWithCallbacks],
  )

  const [dropzoneProps, inputProps] = extractProps(otherProps, dropzonePropKeys)
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    ...dropzoneProps,
    onDrop: handleDropAccepted,
  })

  useEffect(() => {
    if (uploadComplete) {
      Object.entries(asyncUploadedFiles.current).forEach(([fileName, success]) => {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        const file = files[fileName]

        if (file) {
          const newFile = Object.assign(file, {
            status: success ? 'uploaded' : 'error',
          })

          setFiles({
            ...files,
            [fileName]: newFile,
          })
        }
      })

      asyncUploadedFiles.current = {}
      setUploadComplete(false)
    }
  }, [files, uploadComplete])

  const handleRetryUpload = (file: any) => {
    setFiles({
      ...files,
      [file.name]: Object.assign(file, { status: 'uploading' }),
    })

    uploadWithCallbacks(file)
  }

  const handleRemoveFile = (oldFile: any) => {
    setUnloadedFilesCount(unloadedFilesCount - 1)
    // @ts-expect-error TS(2538) FIXME: Type 'any' cannot be used as an index type.
    const { [oldFile.name]: _oldFile, ...newFiles } = files
    // @ts-expect-error TS(2538) FIXME: Type 'any' cannot be used as an index type.
    const { [oldFile.name]: _uploadStatus, ...newAsyncUploadedFiles } = asyncUploadedFiles.current

    asyncUploadedFiles.current = newAsyncUploadedFiles
    setFiles(newFiles)
    if (onValueChange) onValueChange(newFiles)
  }

  return (
    <Card className="text-center">
      <Card.Body {...getRootProps({ style: { overflow: 'visible' } })}>
        <label className="h6" htmlFor={id}>
          {isDragActive ? <>Slip dine dokumenter her...</> : labelContent}
          <input {...getInputProps({ id, ...inputProps })} />
        </label>
        <TransitionGroup
          component="ul"
          className={classNames('list-unstyled', styles.filesList, {
            [styles.filesListEnter]: entering,
            [styles.filesListExit]: exiting,
          })}
          style={{
            transitionDelay: exiting ? `${totalTimeout / 2}ms` : 0,
            transitionDuration: `${totalTimeout / 2}ms`,
            height: `${itemHeight * filesCount + 0.5}em`,
          }}
        >
          {Object.entries(files).map(([key, file], index) => (
            <CSSTransition
              key={key}
              timeout={timeout}
              classNames={extractTransitionClasses({
                styles,
                className: 'fileTransition',
                appearKey: 'enter',
              })}
              onEntered={() => setUnloadedFilesCount(0)}
              onExited={() => setUnloadedFilesCount(0)}
              appear
            >
              <FileItem
                onRemove={handleRemoveFile}
                onRetryUpload={handleRetryUpload}
                file={file}
                index={index}
                stagger={Math.max(timeoutStagger * (index - loadedFilesCount), 0)}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </Card.Body>
    </Card>
  )
}

FileDropzone.defaultProps = {
  files: undefined,
  id: 'fileinput',
  onDropAccepted: undefined,
  onValueChange: undefined,
}

export default FileDropzone
